import Header from "@components/common/Header/index";
import LeftNavigation from "@components/common/LeftNavigation/index";
import "./dashboard.scss";

export default function DashboardLayout(props: any) {
  return (
    <>
      <Header />
      <section className="main-wrapper">
        {/* <div className="main-user-wrapper">
          <LeftNavigation />
        </div> */}
        <div className="dashboard-wrapper">
          {props.children}
          {/* <div className="footer-container">
            <p>
              <b>Visit Support</b>
            </p>
          </div> */}
        </div>
      </section>
    </>
  );
}
