import "./Header.scss";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { clientLogout } from "@services/auth.service";
import { toast } from "react-toastify";
import { useNavigation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleLogout = async () => {
    // let payload = {
    // 	refresh_token: getValue(localStorage, `refreshToken`, ""),
    // };
    // let resp = await clientLogout(payload);
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("user");
    // window.location.href = `/login`;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/login`;
    let payload = {
      refresh_token: getValue(localStorage, `refreshToken`, ""),
    };
    let resp = await clientLogout(payload);
  };
  const handleChangeView = async (view: string) => {
    if (view === "admin") {
      localStorage.setItem("view", "admin");
      window.location.href = `/admin/dashboard`;
    } else {
      localStorage.setItem("view", "client");
      window.location.href = `/`;
    }
  };
  const [user, setUser] = useState({});
  useEffect(() => {
    let user =
      getValue(localStorage, `user`, "") &&
      JSON.parse(getValue(localStorage, `user`, ""));
    setUser(user);
  }, []);

  const [clientNavigationMenu] = useState([
    {
      title: "Organization",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/admin/organisations/active/list",
      parentLink: "/admin/organisations/active/list",
      name: "organization",
    },
    {
      title: "Users",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/admin/users",
      parentLink: "/admin/users",
      name: "users",
    },
  ]);

  return (
    <header className="header-wrapper">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center cursor-pointer">
          <a
            href={
              getValue(localStorage, `view`, "") === "client"
                ? "/"
                : "/admin/dashboard"
            }
            className="d-inline-block"
          >
            <img
              src="/images/logo.png"
              width="160"
              height="52"
              className="img-fluid py-1"
              style={{ height: "48" }}
              alt=""
            />
          </a>
          <div className="d-flex align-items-center ms-5 gap-1 navbar-items-wrapper">
            {clientNavigationMenu.map((item, index) => (
              <div key={index} className="">
                {/* <div key={index} className={item.subMenu ? "nav-dropdown" : ""}> */}
                {/* {item.subMenu ? (
                  <>
                    <button
                      className={`nav-option ${
                        item.subMenu.some(
                          (subItem) => pathname === subItem.link
                        )
                          ? "active"
                          : ""
                      }`}
                    >
                      {item.title}
                    <ChevronDown size={16} /> 
                    </button>
                    <div className="nav-suboption-wrapper">
                      {item.subMenu.map((subItem, subIndex) => (
                        <Link
                          to={subItem.link}
                          key={subIndex}
                          className={`nav-suboption ${
                            pathname === subItem.link ? "active" : ""
                          }`}
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  </>
                ) : ( */}
                <Link
                  to={item.parentLink}
                  key={index}
                  className={`nav-option ${
                    pathname === item.parentLink ? "active" : ""
                  }`}
                >
                  {item.title}
                </Link>
                {/* )} */}
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="switch-dropdown">
            <UncontrolledDropdown>
              {/* <DropdownToggle>
                <div className="switch_header__dropdown m-0">
                  <div className="dropdown-content d-flex cursor-pointer">
                    <img
                      src="/images/switch.svg"
                      // width="173"
                      // height="52"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </DropdownToggle> */}
              <DropdownMenu>
                {/* <DropdownItem header>
                  <div className="switch-dropdown-item">
                    <p>Switch to</p>
                    <img src="/images/close-black.svg" className="img-fluid" />
                  </div>
                </DropdownItem> */}
                <div className="switch-dropdown-item-header">
                  <p>Switch to</p>
                  <img src="/images/close-black.svg" className="img-fluid" />
                </div>
                <DropdownItem onClick={() => handleChangeView("client")}>
                  <div
                    className={`switch-dropdown-item ${
                      getValue(localStorage, `view`, "") !== "admin"
                        ? "active"
                        : ""
                    }`}
                  >
                    <p>My View</p>
                    <img src="/images/my-view.svg" className="img-fluid" />
                  </div>
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeView("admin")}>
                  <div
                    className={`switch-dropdown-item ${
                      getValue(localStorage, `view`, "") === "admin"
                        ? "active"
                        : ""
                    }`}
                  >
                    <p>Admin View</p>
                    <img src="/images/admin-view.svg" className="img-fluid" />
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="bulk-dropdown">
            <UncontrolledDropdown>
              <DropdownToggle>
                <div className="header__dropdown m-0">
                  <div className="dropdown-content d-flex cursor-pointer">
                    <span className="m-auto">
                      {getValue(user, `email`, "").charAt(0).length > 0
                        ? getValue(user, `email`, "").charAt(0).toUpperCase()
                        : "A"}
                    </span>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <h4>Accounts</h4>
                </DropdownItem>
                <DropdownItem>
                  <div className="header__dropdown m-0  text-center d-flex">
                    <div className="dropdown-content d-flex cursor-pointer">
                      <span className="m-auto">
                        {getValue(user, `email`, "").charAt(0).length > 0
                          ? getValue(user, `email`, "").charAt(0).toUpperCase()
                          : "A"}
                      </span>
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <>
                    <p>{getValue(user, `email`, "")}</p>
                    <button className="header-btn">DT Account</button>
                  </>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <div className="text-center">
                    <button
                      className="ascent-button ascent-button--secondary ascent-button--header-buttons"
                      onClick={handleLogout}
                    >
                      Sign out
                    </button>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    </header>
  );
}
